<template>
  <div>
    <search-inner @submit-search="search" @clear-search="reset">
      <el-col :span="6">
        <el-form-item label="消息标题">
          <el-input v-model="searchForm.informationTitle" placeholder="请输入要搜索的模型编号" />
        </el-form-item>
      </el-col>
      <el-col v-if="newsType === 1" :span="6">
        <el-form-item label="状态">
          <el-select v-model="searchForm.status" placeholder="请选择要搜索的状态" filterable>
            <el-option label="全部" value="" />
            <el-option v-for="item in constants.newsStatus" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col v-if="newsType === 2" :span="6">
        <el-form-item label="所属业务">
          <el-select v-model="searchForm.informationType" placeholder="请选择要搜索的业务" filterable>
            <el-option label="全部" value="" />
            <el-option v-for="item in constants.initType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col v-if="newsType === 1" :span="6">
        <el-form-item label="创建人">
          <el-input v-model="searchForm.createdBy" placeholder="请输入要搜索的创建人" />
        </el-form-item>
      </el-col>
      <el-col v-if="newsType === 1" :span="6">
        <el-form-item label="创建时间">
          <ics-search-date :date.sync="createdArr" />
        </el-form-item>
      </el-col>
      <el-col v-if="newsType === 2" :span="6">
        <el-form-item label="变更人">
          <el-input v-model="searchForm.updatedBy" placeholder="请输入要搜索的创建人" />
        </el-form-item>
      </el-col>
      <el-col v-if="newsType === 2" :span="6">
        <el-form-item label="变更时间">
          <ics-search-date :date.sync="updatedArr" />
        </el-form-item>
      </el-col>
    </search-inner>
    <ics-table-inner v-if="newsType === 1" v-loading="loading.list" :column-option="columnTenant" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" class="tableClass" @changePageNum="changePageNum">
      <template slot="btn-inner">
        <el-button type="primary" @click="$router.push({ name: 'newsEdit', params: { editMode: 'add' }, query: { tableActiveName: tableActiveName } })">
          新增
        </el-button>
      </template>
      <template slot="table-columns-before">
        <el-table-column fixed="left" label="序号" type="index" />
      </template>
      <template slot="table-columns-after">
        <el-table-column fixed="right" label="操作" width="140">
          <template v-slot="scope">
            <el-link type="danger" class="text-btn" @click="deleteNews(scope.row.id)">
              删除
            </el-link>
            <el-link v-if="scope.row.status === 0" type="primary" class="text-btn" @click="disableOrEnable(scope.row.id, 1)">
              启动
            </el-link>
            <el-link v-if="scope.row.status === 1" type="danger" class="text-btn" @click="disableOrEnable(scope.row.id, 0)">
              停止
            </el-link>
            <el-link type="primary" class="text-btn" @click="$router.push({ name: 'newsDetail', query: { id: scope.row.id, tableActiveName: tableActiveName } })">
              详情
            </el-link>
          </template>
        </el-table-column>
      </template>
    </ics-table-inner>
    <ics-table-inner v-if="newsType === 2" v-loading="loading.list" :column-option="columnBusiness" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" class="tableClass" @changePageNum="changePageNum">
      <template slot="table-columns-before">
        <el-table-column fixed="left" label="序号" type="index" />
      </template>
      <template slot="table-columns-after">
        <el-table-column fixed="right" label="操作" width="140">
          <template v-slot="scope">
            <el-link type="primary" class="text-btn" @click="$router.push({ name: 'newsInitEdit', params: { editMode: 'edit' }, query: { informationCode: scope.row.informationCode, tableActiveName: tableActiveName } })">
              编辑
            </el-link>
            <el-link type="primary" class="text-btn" @click="$router.push({ name: 'newsInitDetail', query: { informationCode: scope.row.informationCode, tableActiveName: tableActiveName } })">
              详情
            </el-link>
          </template>
        </el-table-column>
      </template>
    </ics-table-inner>
  </div>
</template>

<script>
import IcsTableInner from '@/components/table-inner'
import IcsSearchDate from '@/components/search-date'
import utils from '@/assets/js/utils'
export default {
  name: 'IcsNewsListInner',
  components: { IcsTableInner, IcsSearchDate },
  props: {
    newsType: {
      type: [Number, String],
      default: ''
    },
    tableList: {
      type: Object,
      default () {
        return {}
      }
    },
    tableActiveName: {
      type: String,
      default: ''
    },
    loading: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      searchForm: {
        informationTitle: '',
        status: '',
        informationType: '',
        createdBy: '',
        createdBeginAt: '',
        createdEndAt: '',
        updatedBy: '',
        updatedBeginAt: '',
        updatedEndAt: '',
      },
      columnTenant: [
        { label: '消息配置编号', prop: 'informationCode', formatter: this.utils.isEffective, minWidth: 100 },
        { label: '消息标题', prop: 'informationTitle', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '消息类型', prop: 'informationType', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'newsType'), minWidth: 100 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
      ],
      columnBusiness: [
        { label: '消息配置编号', prop: 'informationCode', formatter: this.utils.isEffective, minWidth: 100 },
        { label: '消息标题', prop: 'informationTitle', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '业务类型', prop: 'informationType', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'initType'), minWidth: 100 },
        { label: '更新时间', prop: 'updatedAt', formatter: this.utils.isEffective, minWidth: 140 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdBeginAt', 'createdEndAt'),
    updatedArr: utils.computedDate('updatedBeginAt', 'updatedEndAt'),
  },
  watch: {
    newsType: {
      handler (val) {
      },
      immediate: true
    }
  },
  methods: {
    search () {
      this.$emit('search', this.searchForm)
    },
    reset () {
      this.searchForm = {}
      this.$emit('search', this.searchForm)
    },
    changePageNum ({ pageNum }) {
      this.searchForm.pageNum = pageNum
      this.$emit('search', this.searchForm)
    },
    disableOrEnable (val, enabled) {
      let api = this.api.system.information.enable
      if (enabled === 0) {
        api = this.api.system.information.disable
      }
      api(val).then(result => {
        if (result.data.success === true) {
          this.loading.list = false
          this.$message.success('操作成功')
          this.search()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.list = false
      })
    },
    deleteNews (val) {
      this.$confirm('此操作将永久删除该项消息配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.system.information.deleteTemplate(val).then(result => {
          if (result.data.success === true) {
            this.loading.list = false
            this.$message.success('操作成功')
            this.search()
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.list = false
        })
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="less">
.tableClass {
  .el-table__fixed-right {
    height: 100% !important;
  }
}
</style>
