<template>
  <el-main>
    <page-title />
    <div class="tabs-inner">
      <el-tabs v-model="activeName" type="card" @tab-click="tabClickRoleList()">
        <el-tab-pane label="平台消息" name="tenant">
          <ics-news-list-inner :loading="loading" :news-type="1" :table-list="tableList" :table-active-name="activeName" @search="tabClickRoleList" />
        </el-tab-pane>
        <el-tab-pane label="业务消息" name="business">
          <ics-news-list-inner :loading="loading" :news-type="2" :table-list="tableList" :table-active-name="activeName" @search="tabClickRoleList" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-main>
</template>

<script>
import IcsNewsListInner from './components/news-list-inner'
export default {
  components: { IcsNewsListInner },
  data () {
    return {
      activeName: this.$route.query.activeName ? this.$route.query.activeName : 'tenant',
      processList: [],
      tableList: {},
      loading: {
        list: false
      }
    }
  },
  computed: {},
  created () {
    this.tabClickRoleList()
  },
  methods: {
    tabClickRoleList (searchForm) {
      if (!this.activeName) this.activeName = 'tenant'
      let searchData = searchForm
      if (searchData) {
        searchData.pageSize = 10
      } else {
        searchData = {
          pageSize: 10
        }
      }
      console.log(searchData)
      let api = this.api.system.information.pageList
      if (this.activeName === 'business') {
        api = this.api.system.information.initPageList
      }
      this.loading.list = true
      api(this.utils.filterTrimValue(searchData)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    }
  }
}
</script>

<style>

</style>
